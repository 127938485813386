import dayjs from 'dayjs'
import { filter, find, isNumber, keyBy, map } from 'lodash'
import { useMemo } from 'react'

import { useNowMemo } from '~/data/time/hooks'
import type { TimeRange } from '~/data/time/types'
import type { Tuple } from '~/data/types'
import type { LoadManagementOption } from '~/data/types/site'
import useLogger from '~/debug/logger'
import { useCurrentOrgId } from '~/store/slices/currentOrg'

import type {
  ChargeManagementConfiguration,
  DepotEventModel,
  DepotModel,
  GetChargerEventsArgs,
  GetDepotArgs,
  GetDepotsArgs,
  SiteEnergyConfiguration
} from '../client'
import {
  useGetBulkSiteEnergyConfigurationsQuery,
  useGetChargerEventsQuery,
  useGetDepotEventsByDepotIdQuery,
  useGetDepotEventsQuery,
  useGetDepotQuery,
  useGetDepotsQuery,
  useGetSiteEnergyConfigurationsQuery,
  useGetWeatherQuery
} from '../client'
import type { UsePollingOptions, UseQueryOptions } from './polling'
import { usePolling } from './polling'
import type { MaybeDateRange } from './types'
import { useSiteHomeSiteFilterCallback } from './useHomeSiteFiltering'
import { combineSkip, useValidatedDateRange } from './utils'

/******************************************************************************
 * GET /depots/{depotId}
 ******************************************************************************/
type UseSiteArgs = Omit<GetDepotArgs, 'depotId'>

export function usePolledSite(siteId?: string, args?: UseSiteArgs, options?: UsePollingOptions) {
  const result = usePolling(useGetDepotQuery, { depotId: siteId ?? '', ...args }, combineSkip(!siteId, options))

  // Ensure the site returned from the hook matches the ID passed in. This is needed when the ID
  // changes or is cleared.
  const site = result.data?.depotId === siteId ? result.data : undefined
  return { site, ...result }
}

export function useGetSite(siteId?: string, args?: UseSiteArgs, skip?: boolean) {
  return usePolledSite(siteId, args, { intervalSeconds: 0, skip })
}

/******************************************************************************
 * GET /depots/{depotId}/chargers/events
 ******************************************************************************/
type ChargerEventFilters = Pick<
  GetChargerEventsArgs,
  'siteId' | 'chargerId' | 'connectorId' | 'vehicleId' | 'targetType'
>

type UseChargerEventsArgs = Omit<
  Partial<GetChargerEventsArgs>,
  keyof ChargerEventFilters | 'startsBefore' | 'endsAfter'
>

export function usePolledChargerEvents(
  filters?: ChargerEventFilters,
  dateRange?: MaybeDateRange,
  args?: UseChargerEventsArgs,
  options?: UsePollingOptions
) {
  const { from, to } = useValidatedDateRange(dateRange)
  const result = usePolling(
    useGetChargerEventsQuery,
    { ...filters, startsBefore: to.time, endsAfter: from.time, page: 0, count: 1000, ...args },
    combineSkip(!to.valid || !from.valid, options)
  )

  return { chargerEvents: result.data?.items, ...result }
}

export function useChargerEvents(
  filters?: ChargerEventFilters,
  dateRange?: MaybeDateRange,
  args?: UseChargerEventsArgs
) {
  return usePolledChargerEvents(filters, dateRange, args, { intervalSeconds: 0 })
}

type EventParams = {
  chargerId?: string
  siteId?: string
}

/**
 * Retrieves the number of charger events for the next day and week.
 * Either chargerId or siteId must be provided, but not both.
 */
export function useChargerEventCounts({ chargerId, siteId }: EventParams) {
  const logger = useLogger('useChargerEventCounts')
  if (chargerId && siteId) {
    logger.warn('Both chargerId and siteId provided')
  }

  const now = useNowMemo()
  const timeRange = { from: now, to: now.add(1, 'week') }

  const { data: chargerEvents } = useGetChargerEventsQuery({
    chargerId,
    siteId,
    page: 0,
    count: 1000,
    targetType: 'CHARGER',
    startsAfter: timeRange.from.toISOString(),
    startsBefore: timeRange.to.toISOString()
  })

  return useMemo(() => {
    const cutoff = now.add(1, 'day')
    const nextDay = (chargerEvents?.items ?? []).filter((e) => dayjs(e.scheduledStart).isBefore(cutoff)).length
    const nextWeek = chargerEvents?.totalItems ?? 0

    return { nextDay, nextWeek }
  }, [now, chargerEvents])
}

/******************************************************************************
 * GET /depots/events
 ******************************************************************************/
export function useActiveSiteEvents() {
  const now = useNowMemo()
  const result = usePolling(useGetDepotEventsQuery, {
    from: now.subtract(1, 'minute').toISOString(),
    to: now.toISOString()
  })

  return { siteEvents: result.data, ...result }
}

/******************************************************************************
 * GET /depots/{depotId}/events
 ******************************************************************************/
const siteEventBoundaryFn = (event: DepotEventModel): Tuple<string> => [event.eventStartDate, event.eventEndDate]
export function useSiteEventsForSite(siteId: string, timeRange: TimeRange) {
  const result = usePolling(
    useGetDepotEventsByDepotIdQuery,
    { depotId: siteId ?? '', from: timeRange.from.toISOString(), to: timeRange.to.toISOString() },
    { skip: !siteId }
  )

  return { siteEvents: useCategorizedEvents(result.data, siteEventBoundaryFn), ...result }
}

export function useActiveSiteEventsForSite(siteId: string = '') {
  const now = useNowMemo()
  return useSiteEventsForSite(siteId, { from: now.subtract(1, 'minute'), to: now })
}

type EventCategory = 'all' | 'unfinished' | 'active' | 'nextDay' | 'nextWeek'

function useCategorizedEvents<T>(events: T[] | undefined, boundaryFn: (event: T) => Tuple<dayjs.ConfigType>) {
  const now = useNowMemo((d) => d)
  return useMemo(() => {
    const categories = { all: [], unfinished: [], active: [], nextDay: [], nextWeek: [] } as Record<EventCategory, T[]>

    events?.forEach((event) => {
      const eventCategories: EventCategory[] = ['all']

      const [start, end] = boundaryFn(event)
      const unfinished = now.isBefore(end)
      const oneDayLater = now.add(1, 'day')
      const oneWeekLater = now.add(1, 'week')

      if (unfinished) {
        eventCategories.push('unfinished')
        if (now.isAfter(start)) {
          eventCategories.push('active')
        } else {
          if (oneDayLater.isAfter(start)) eventCategories.push('nextDay')
          if (oneWeekLater.isAfter(start)) eventCategories.push('nextWeek')
        }
      }

      eventCategories.forEach((category) => categories[category].push(event))
    })

    return categories
  }, [events, now, boundaryFn])
}

/******************************************************************************
 * GET /depots
 ******************************************************************************/
export function usePolledSites(args?: GetDepotsArgs, options?: UsePollingOptions) {
  const result = usePolling(useGetDepotsQuery, { count: 1000, page: 0, ...args }, options)

  const filter = useSiteHomeSiteFilterCallback()
  const sites = useMemo(() => {
    return {
      ...result.data,
      items: result.data?.items?.filter(filter) ?? []
    }
  }, [result.data, filter])
  return { sites, ...result }
}

type UseSitesArgs = Omit<GetDepotsArgs, 'fleetId' | 'organizationId'>
export function useSites(args?: GetDepotsArgs, options?: UseQueryOptions) {
  return usePolledSites(args, { ...options, intervalSeconds: 0 })
}

export function useOrgSites(orgId: string, args?: UseSitesArgs) {
  return useSites({ organizationId: orgId, ...args }, { skip: !orgId })
}

export function useCurrentOrgSites(args?: UseSitesArgs) {
  return useOrgSites(useCurrentOrgId(), args)
}

export function useOrgSitesMap(args?: GetDepotsArgs, options?: UsePollingOptions) {
  const { isSuccess, isLoading, sites } = usePolledSites(args, options)
  const orgSites = useMemo(() => keyBy(sites?.items ?? [], 'depotId'), [sites])
  return { orgSites, isLoading, isSuccess }
}

/******************************************************************************
 * GET /sites/{siteId}/energy-configurations
 ******************************************************************************/
export function useLatestSiteEnergyConfiguration(siteId: string = '') {
  const result = useGetSiteEnergyConfigurationsQuery({ siteId, latest: true }, { skip: !siteId })
  return useMemo(() => parseSiteEnergyConfiguration(result.data?.at(0)), [result.data])
}

export function useSiteHasLoadManagementEnabled(siteId: string = '', values?: LoadManagementOption[]) {
  const { chargeManagementConfiguration } = useLatestSiteEnergyConfiguration(siteId) ?? {}
  return siteHasLoadManagementEnabled(chargeManagementConfiguration, values)
}

function siteHasLoadManagementEnabled(
  { loadManagement }: ChargeManagementConfiguration = {},
  values: LoadManagementOption[] = ['FIXED', 'DYNAMIC']
) {
  return Boolean(loadManagement && values.includes(loadManagement))
}

/**
 * Parses a site energy configuration to ensure that the site limit is only returned if load
 * management is enabled. This should be ensured by the API, but as of 5/30/24 it is not.
 */
function parseSiteEnergyConfiguration(config?: SiteEnergyConfiguration) {
  const cmConfig = config?.chargeManagementConfiguration
  return {
    ...config,
    chargeManagementConfiguration: {
      ...config?.chargeManagementConfiguration,
      siteLimit: siteHasLoadManagementEnabled(cmConfig) ? cmConfig?.siteLimit : undefined
    }
  }
}

/**
 * Returns the active site limit for a site. This loads all active site events and the latest site
 * energy configuration. Returns the site event's curtailed site limit if it exists, otherwise the
 * site limit from the energy configuration.
 */
export function useActiveSiteLimit(siteId?: string) {
  const { chargeManagementConfiguration } = useLatestSiteEnergyConfiguration(siteId) ?? {}
  const { siteEvents } = useActiveSiteEventsForSite(siteId)

  return useMemo(() => {
    const siteEvent = find(siteEvents.unfinished, { depotId: siteId })
    const { curtailedSiteLimit } = siteEvent?.schedule.intervals[0] ?? {}
    return curtailedSiteLimit ?? chargeManagementConfiguration?.siteLimit
  }, [chargeManagementConfiguration, siteEvents, siteId])
}

/******************************************************************************
 * GET /sites/energy-configurations
 ******************************************************************************/
export function useBulkSiteEnergyConfigurations() {
  const result = useGetBulkSiteEnergyConfigurationsQuery({ organizationId: useCurrentOrgId() })
  const parsed = useMemo(() => map(result.data, parseSiteEnergyConfiguration), [result.data])
  return { siteEnergyConfigurations: parsed, ...result }
}

export function useBulkSiteEnergyConfigurationsMap() {
  const result = useBulkSiteEnergyConfigurations()
  const siteEnergyConfigurationsMap = useMemo(() => keyBy(result.siteEnergyConfigurations, 'siteId'), [result])
  return { siteEnergyConfigurationsMap, ...result }
}

export function useSitesWithDynamicLoadManagement() {
  const { siteEnergyConfigurationsMap } = useBulkSiteEnergyConfigurationsMap()
  return useMemo(() => {
    const withDynamicLoadMgmt = filter(siteEnergyConfigurationsMap, (config) => {
      return config.chargeManagementConfiguration.loadManagement === 'DYNAMIC'
    })

    return new Set(map(withDynamicLoadMgmt, 'siteId'))
  }, [siteEnergyConfigurationsMap])
}

export function useSiteWeather(site?: DepotModel) {
  const { latitude, longitude } = site ?? {}
  return useGetWeatherQuery(
    { lat: latitude ?? 0, lon: longitude ?? 0 },
    { skip: !isNumber(latitude) || !isNumber(longitude) }
  )
}
