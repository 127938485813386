/**
 * Config
 * -------------------------------------------------------------------------------------
 * ! IMPORTANT: Make sure you clear the browser local storage in order to see the config changes in the template.
 * ! To clear local storage, you may refer https://www.leadshook.com/help/how-to-clear-local-storage-in-google-chrome-browser/.
 */
import type { Direction } from '@mui/material'
import type { Mode } from 'src/@core/layouts/types'

type ThemeConfig = {
  mode: Mode
  direction: Direction
  navCollapsed: boolean
}

const themeConfig: ThemeConfig = {
  mode: 'light' /* light | dark | semi-dark */,
  direction: 'ltr' /* ltr | rtl */,
  navCollapsed: false /* true | false */
}

export default themeConfig
