import type { SxProps } from '@mui/material'
import { Box } from '@mui/material'
import type { PropsWithChildren } from 'react'

type ColorDotProps = PropsWithChildren<{
  color: string
  size?: number
  sx?: SxProps
  pattern?: 'slantedLines'
  onClick?: () => void
}>

export default function ColorDot({ color, size = 8, sx, pattern, children, ...rest }: ColorDotProps) {
  const patternId = `stripe-pattern-${color.replace('#', '')}`

  return (
    <Box
      {...rest}
      sx={{
        width: size,
        height: size,
        borderRadius: '50%',
        backgroundColor: pattern ? 'white' : color,
        display: 'inline-block',
        ...sx
      }}
    >
      {pattern === 'slantedLines' ? (
        <svg width="100%" height="100%">
          <defs>
            <pattern id={patternId} width="4" height="4" patternUnits="userSpaceOnUse" patternTransform="rotate(-45)">
              <line
                x1="0"
                y1="0"
                x2="0"
                y2="4"
                style={{
                  stroke: color,
                  strokeWidth: 2
                }}
              />
            </pattern>
          </defs>
          <circle cx="50%" cy="50%" r="50%" fill={`url(#${patternId})`} />
        </svg>
      ) : (
        children
      )}
    </Box>
  )
}
