import Box, { BoxProps } from '@mui/material/Box'
import List from '@mui/material/List'
import { ThemeProvider, alpha, createTheme, responsiveFontSizes, styled } from '@mui/material/styles'
import { PropsWithChildren, useRef, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { useSettings } from '@core/context/settingsContext'
import themeOptions from '@core/theme/ThemeOptions'

import useNavItems from '~/navigation/vertical'

import Drawer from './Drawer'
import VerticalNavHeader from './VerticalNavHeader'
import VerticalNavItems from './VerticalNavItems'

type Props = PropsWithChildren<{
  navWidth: number
  navVisible: boolean
  collapsedNavWidth: number
  hidden: boolean
  navigationBorderWidth: number
  toggleNavVisibility: () => void
  setNavVisible: (value: boolean) => void
}>

const StyledBoxForShadow = styled(Box)<BoxProps>(({ theme }) => ({
  top: 0,
  left: -8,
  zIndex: 2,
  opacity: 0,
  position: 'absolute',
  pointerEvents: 'none',
  width: 'calc(100% + 15px)',
  height: theme.mixins.toolbar.minHeight,
  transition: 'opacity .15s ease-in-out',
  background: `linear-gradient(${theme.palette.background.default} ${theme.direction === 'rtl' ? '95%' : '5%'},${alpha(
    theme.palette.background.default,
    0.85
  )} 30%,${alpha(theme.palette.background.default, 0.5)} 65%,${alpha(
    theme.palette.background.default,
    0.3
  )} 75%,transparent)`,
  '&.scrolled': {
    opacity: 1
  }
}))

const Navigation = (props: Props) => {
  const { hidden } = props
  const { settings } = useSettings()

  const [navHover, setNavHover] = useState<boolean>(false)
  const [groupActive, setGroupActive] = useState<string[]>([])
  const [currentActiveGroup, setCurrentActiveGroup] = useState<string[]>([])

  const shadowRef = useRef<HTMLElement>(null)
  const darkTheme = responsiveFontSizes(createTheme(themeOptions(settings, 'dark')))

  const handleInfiniteScroll = (ref: HTMLElement) => {
    if (ref) {
      // @ts-ignore
      ref._getBoundingClientRect = ref.getBoundingClientRect

      ref.getBoundingClientRect = () => {
        // @ts-ignore
        const original = ref._getBoundingClientRect()

        return { ...original, height: Math.floor(original.height) }
      }
    }
  }

  const scrollMenu = (container: any) => {
    if (!shadowRef.current) return

    container = hidden ? container.target : container
    if (container.scrollTop > 0) {
      if (!shadowRef.current.classList.contains('scrolled')) {
        shadowRef.current.classList.add('scrolled')
      }
    } else {
      shadowRef.current.classList.remove('scrolled')
    }
  }

  const ScrollWrapper = hidden ? Box : PerfectScrollbar

  return (
    <ThemeProvider theme={darkTheme}>
      <Drawer {...props} navHover={navHover} setNavHover={setNavHover}>
        <VerticalNavHeader {...props} navHover={navHover} />
        <Box sx={{ position: 'relative', overflow: 'hidden' }}>
          <StyledBoxForShadow ref={shadowRef} />
          {/* @ts-ignore */}
          <ScrollWrapper
            {...(hidden
              ? {
                  onScroll: (container: any) => scrollMenu(container),
                  sx: { height: '100%', overflowY: 'auto', overflowX: 'hidden' }
                }
              : {
                  options: { wheelPropagation: false },
                  onScrollY: (container: any) => scrollMenu(container),
                  containerRef: (ref: any) => handleInfiniteScroll(ref)
                })}
          >
            <List className="nav-items" sx={{ pt: 0, '& > :first-of-type': { mt: '0' } }}>
              <VerticalNavItems
                navHover={navHover}
                setNavHover={setNavHover}
                groupActive={groupActive}
                setGroupActive={setGroupActive}
                currentActiveGroup={currentActiveGroup}
                setCurrentActiveGroup={setCurrentActiveGroup}
                navItems={useNavItems()}
                {...props}
              />
            </List>
          </ScrollWrapper>
        </Box>
      </Drawer>
    </ThemeProvider>
  )
}

export default Navigation
