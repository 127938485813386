import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import { dispatcherFactory, selectorHookFactory } from '~/store/utils'

type FirmwareErrorState = {
  errorMessages: Record<string, string>
}

const initialState: FirmwareErrorState = {
  errorMessages: {}
}

const firmwareErrorSlice = createSlice({
  name: 'firmwareError',
  initialState,
  reducers: {
    reset: () => initialState,

    setErrorMessage: (state, action: PayloadAction<{ chargerId: string; message: string }>) => {
      const { chargerId, message } = action.payload
      state.errorMessages[chargerId] = message
    },

    clearErrorMessage: (state, action: PayloadAction<string>) => {
      delete state.errorMessages[action.payload]
    },

    clearAllErrorMessages: (state) => ({
      ...state,
      errorMessages: {}
    })
  }
})

export default firmwareErrorSlice.reducer

// Selectors
export const useErrorMessages = selectorHookFactory((state) => state.firmwareError.errorMessages)

// Actions
const { reset, setErrorMessage, clearErrorMessage, clearAllErrorMessages } = firmwareErrorSlice.actions
export { reset }

// Action Dispatchers
export const useSetErrorMessage = dispatcherFactory(setErrorMessage)
export const useClearErrorMessage = dispatcherFactory(clearErrorMessage)
export const useClearAllErrorMessages = dispatcherFactory(clearAllErrorMessages)

export function useErrorMessage(chargerId: string) {
  return useErrorMessages()[chargerId]
}
