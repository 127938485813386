import { ArrowUpward } from '@mui/icons-material'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import { styled } from '@mui/material/styles'
import { PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react'

import { useSettings } from '@core/context/settingsContext'

import Banners from '~/layouts/components/banners'
import { useBannerOffset } from '~/store/slices/display'

import AppBar from './components/AppBar'
import ScrollToTop from './components/ScrollToTop'
import Navigation from './components/navigation'

type LayoutProps = PropsWithChildren<{
  hidden: boolean
  scrollToTop?: (props?: any) => ReactNode
}>

const Layout = (props: LayoutProps) => {
  const { hidden, children, scrollToTop } = props
  const { settings, saveSettings } = useSettings()
  const isCollapsed = useRef(settings.navCollapsed)
  const [navVisible, setNavVisible] = useState<boolean>(false)

  const toggleNavVisibility = () => setNavVisible(!navVisible)
  const bannerOffset = useBannerOffset()

  useEffect(() => {
    if (hidden) {
      if (settings.navCollapsed) {
        saveSettings({ ...settings, navCollapsed: false })
        isCollapsed.current = true
      }
    } else if (isCollapsed.current) {
      saveSettings({ ...settings, navCollapsed: true })
      isCollapsed.current = false
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidden])

  return (
    <>
      {/* Timezone and cloaking banners */}
      {<Banners />}

      <LayoutWrapper className="layout-wrapper">
        {/* Navigation Menu */}
        <Navigation
          navWidth={260}
          navVisible={navVisible}
          setNavVisible={setNavVisible}
          collapsedNavWidth={68}
          toggleNavVisibility={toggleNavVisibility}
          navigationBorderWidth={0}
          {...props}
        />
        <MainContentWrapper className="layout-content-wrapper" bannerOffset={bannerOffset}>
          {/* AppBar Component */}
          <AppBar toggleNavVisibility={toggleNavVisibility} {...props} />

          {/* Content */}
          <ContentWrapper className="layout-page-content">{children}</ContentWrapper>
        </MainContentWrapper>
      </LayoutWrapper>

      {/* Scroll to top button */}
      {scrollToTop ? (
        scrollToTop(props)
      ) : (
        <ScrollToTop className="mui-fixed">
          <Fab color="primary" size="small" aria-label="scroll back to top">
            <ArrowUpward />
          </Fab>
        </ScrollToTop>
      )}
    </>
  )
}

export default Layout

const LayoutWrapper = styled('div')({
  height: '100%',
  display: 'flex'
})

type MainContentProps = {
  bannerOffset: number
}

const MainContentWrapper = styled(Box, { shouldForwardProp: (prop) => prop !== 'bannerOffset' })<MainContentProps>(
  ({ bannerOffset }) => ({
    flexGrow: 1,
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column',

    // Leave space for the banners, but take up all that remains
    minHeight: `calc(100vh - ${bannerOffset}px)`,
    marginTop: bannerOffset
  })
)

const ContentWrapper = styled('main')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  padding: theme.spacing(6),
  transition: 'padding .25s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  }
}))
