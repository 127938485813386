import { compact, keyBy, map } from 'lodash'
import { useMemo } from 'react'

import { useCurrentOrgId } from '~/store/slices/currentOrg'

import type { GetVehiclesForOrganizationArgs, VehicleModel } from '../client'
import { useGetVehicleQuery, useGetVehiclesBySiteQuery, useGetVehiclesForOrganizationQuery } from '../client'
import type { UsePollingOptions, UseQueryOptions } from './polling'
import { usePolling } from './polling'
import { useVehicleHomeSiteFilterCallback } from './useHomeSiteFiltering'
import { combineSkip, useTransformedDict } from './utils'

/******************************************************************************
 * GET /organizations/{orgId}/vehicles
 ******************************************************************************/
export function usePolledVehicles(args?: GetVehiclesForOrganizationArgs, options?: UsePollingOptions) {
  const currentOrgId = useCurrentOrgId()
  const orgId = args?.id ?? currentOrgId
  const result = usePolling(useGetVehiclesForOrganizationQuery, { id: orgId }, combineSkip(!orgId, options))

  const filter = useVehicleHomeSiteFilterCallback()
  const vehicles = useMemo(() => result.data?.filter(filter) ?? [], [result.data, filter])
  return { vehicles, ...result }
}

export function useVehicles(args?: GetVehiclesForOrganizationArgs, options?: UsePollingOptions) {
  return usePolledVehicles(args, { ...options, intervalSeconds: 0 })
}

export function useOrgVehicles(orgId?: string, options?: UsePollingOptions) {
  return useVehicles({ id: orgId ?? '' }, combineSkip(!orgId, options))
}

export function useSiteVehicles(siteId: string) {
  const result = usePolling(useGetVehiclesBySiteQuery, { id: siteId }, { intervalSeconds: 0 })

  return { vehicles: result.data || [], ...result }
}

export function useAtHomeVehicles(siteId: string) {
  const { vehicles } = useSiteVehicles(siteId)
  return useMemo(() => {
    return vehicles.filter((vehicle) => {
      return vehicle.currentLocation?.locationOfInterestModels?.some((loi) => loi.id === siteId)
    })
  }, [vehicles, siteId])
}

export function useCurrentOrgVehicles() {
  return useOrgVehicles(useCurrentOrgId())
}

export function useOrgVehiclesMap(args?: GetVehiclesForOrganizationArgs, options?: UsePollingOptions) {
  const { isSuccess, isLoading, isFetching, vehicles } = usePolledVehicles(args, options)
  const orgVehicles = useMemo(() => keyBy(vehicles ?? [], 'id'), [vehicles])

  return {
    orgVehicles,
    isLoading,
    isFetching,
    isSuccess,
    lookup: (vehicleId?: string) => (vehicleId ? orgVehicles[vehicleId] : undefined)
  }
}

/**
 * Vehicles can have multiple auth tags, as well as change auth tags over time, so this function
 * maps all current and historical auth tags to their vehicle record to allow for lookup by auth
 * tag even if that tag is deactivated.
 */
export function useVehicleMapByAllAuthTags(vehiclesInput?: VehicleModel[]) {
  // Fetch vehicles if they are not provided
  const { vehicles: vehiclesFetched, ...result } = useVehicles(undefined, { skip: !!vehiclesInput })
  const vehicles = vehiclesInput ?? vehiclesFetched

  const withAllAuthTags = useMemo(() => {
    const filteredVehicles = vehicles.filter((v) => !!v.vehicleAuthTags?.length)
    return filteredVehicles.reduce((acc: Record<string, VehicleModel>, vehicle) => {
      const tags = compact(map(vehicle.vehicleAuthTags, 'authTag'))
      tags.forEach((tag) => (acc[tag] = vehicle))
      return acc
    }, {})
  }, [vehicles])

  return {
    vehicleMapByAllAuthTags: useTransformedDict(withAllAuthTags, {
      caseInsensitive: true,
      prefix: ['VID:', 'EVCCID:']
    }),
    ...result
  }
}

/**
 * This hook differs from useVehicleMapByAllAuthTags in that it only includes auth tags that
 * are currently mapped to one of the vehicle's charge ports, and therefore are currently active.
 * This is useful for real-time queries, like "which vehicle is currently charging on this charger?"
 */
export function useVehicleMapByActiveAuthTags(vehiclesInput?: VehicleModel[]) {
  // Fetch vehicles if they are not provided
  const { vehicles: vehiclesFetched, ...result } = useVehicles(undefined, { skip: !!vehiclesInput })
  const vehicles = vehiclesInput ?? vehiclesFetched

  const withActiveAuthTags = useMemo(() => {
    const filteredVehicles = vehicles.filter((v) => !!v.chargePorts?.length)
    return filteredVehicles.reduce((acc: Record<string, VehicleModel>, vehicle) => {
      const tags = compact(map(vehicle.chargePorts, 'authTag'))
      tags.forEach((tag) => (acc[tag] = vehicle))
      return acc
    }, {})
  }, [vehicles])

  return {
    vehicleMapByActiveAuthTags: useTransformedDict(withActiveAuthTags, {
      caseInsensitive: true,
      prefix: ['VID:', 'EVCCID:']
    }),
    ...result
  }
}

/**
 * This hook maps vehicles by their charge port ID. This is useful when you need to look up vehicles
 * by Ports that don't have an auth tag assigned, for example when mapping a new auth tag to a vehicle.
 */
export function useVehicleMapByPortId(vehiclesInput?: VehicleModel[]) {
  // Fetch vehicles if they are not provided
  const { vehicles: vehiclesFetched, ...result } = useVehicles(undefined, { skip: !!vehiclesInput })
  const vehicles = vehiclesInput ?? vehiclesFetched

  const mapByPortId = useMemo(() => {
    const filteredVehicles = vehicles.filter((v) => !!v.chargePorts?.length)
    return filteredVehicles.reduce((acc: Record<string, VehicleModel>, vehicle) => {
      const portIds = map(vehicle.chargePorts, 'id')
      portIds.forEach((id) => (acc[id] = vehicle))
      return acc
    }, {})
  }, [vehicles])

  return {
    vehicleMapByPortId: useTransformedDict(mapByPortId, { caseInsensitive: true }),
    ...result
  }
}

export function useVehicleByAuthTag(authTag?: string) {
  const { vehicleMapByAllAuthTags, ...result } = useVehicleMapByAllAuthTags()
  return { vehicle: authTag ? vehicleMapByAllAuthTags[authTag] : undefined, ...result }
}

/******************************************************************************
 * GET /vehicles/{vehicleId}
 ******************************************************************************/
export function usePolledVehicle(vehicleId?: string, options?: UsePollingOptions) {
  const result = usePolling(useGetVehicleQuery, { id: vehicleId ?? '' }, combineSkip(!vehicleId, options))

  // Ensure the vehicle returned from the hook matches the ID passed in. This is needed when the ID
  // changes or is cleared.
  const vehicle = result.data?.id === vehicleId ? result.data : undefined
  return { vehicle, ...result }
}

export function useVehicle(vehicleId?: string, options?: UseQueryOptions) {
  return usePolledVehicle(vehicleId, { ...options, intervalSeconds: 0 })
}
