// ** Type Import
import { alpha } from '@mui/material'

import { OwnerStateThemeType } from './'

// ** Util Import

const Progress = () => {
  return {
    MuiLinearProgress: {
      styleOverrides: {
        root: ({ theme }: OwnerStateThemeType) => ({
          height: 6,
          borderRadius: theme.shape.borderRadius,
          '&.MuiLinearProgress-colorPrimary': {
            backgroundColor: alpha(theme.palette.primary.main, 0.12)
          },
          '&.MuiLinearProgress-colorSecondary': {
            backgroundColor: alpha(theme.palette.secondary.main, 0.12)
          },
          '&.MuiLinearProgress-colorSuccess': {
            backgroundColor: alpha(theme.palette.success.main, 0.12)
          },
          '&.MuiLinearProgress-colorError': {
            backgroundColor: alpha(theme.palette.error.main, 0.12)
          },
          '&.MuiLinearProgress-colorWarning': {
            backgroundColor: alpha(theme.palette.warning.main, 0.12)
          },
          '&.MuiLinearProgress-colorInfo': {
            backgroundColor: alpha(theme.palette.info.main, 0.12)
          }
        }),
        bar: ({ theme }: OwnerStateThemeType) => ({
          borderRadius: theme.shape.borderRadius
        })
      }
    }
  }
}

export default Progress
