import { Box, Chip, Divider, ListItemIcon, Menu, Stack, Typography } from '@mui/material'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import clsx from 'clsx'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import type { MouseEvent } from 'react'
import { useEffect, useState } from 'react'
import { useSettings } from 'src/@core/context/settingsContext'
import Translations from 'src/layouts/components/Translations'

import { NavGroup, NavLink, NavPopoutGroup } from '@core/layouts/types'

import CanViewNavGroup from '~/layouts/components/acl/CanViewNavGroup'
import CanViewNavLink from '~/layouts/components/acl/CanViewNavLink'

import MenuNavLink from './MenuNavLink'
import { hasActiveChild } from './utils'

interface Props {
  item: NavPopoutGroup
  navHover?: boolean
  navigationBorderWidth: number
  collapsedNavWidth?: number
  navVisible?: boolean
  toggleNavVisibility: () => void
  setNavHover: (values: boolean) => void
  groupActive: string[]
  setGroupActive: (values: string[]) => void
  currentActiveGroup: string[]
  setCurrentActiveGroup: (items: string[]) => void
}

const VerticalNavPopoutItem = ({
  item,
  navHover,
  navigationBorderWidth,
  collapsedNavWidth,
  navVisible,
  toggleNavVisibility,
  setNavHover,
  groupActive,
  setGroupActive,
  currentActiveGroup,
  setCurrentActiveGroup
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { navCollapsed } = useSettings().settings

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  const pathname = usePathname()

  useEffect(() => {
    if (hasActiveChild(item, pathname)) {
      if (!groupActive.includes(item.title)) groupActive.push(item.title)
    } else {
      const index = groupActive.indexOf(item.title)
      if (index > -1) groupActive.splice(index, 1)
    }
    setGroupActive([...groupActive])
    setCurrentActiveGroup([...groupActive])

    // Empty Active Group When Menu is collapsed and not hovered, to fix issue route change
    if (navCollapsed && !navHover) {
      setGroupActive([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const isNavLinkActive = (item: NavLink) => {
    return item.path && pathname.startsWith(item.path)
  }

  const navMinimized = navCollapsed && !navHover
  const navLinkPadding = ((collapsedNavWidth ?? 0) - navigationBorderWidth - 24 - 16) / 8
  return (
    <CanViewNavGroup navGroup={item}>
      <>
        <ListItem
          disablePadding
          className="nav-group"
          onClick={handleClick}
          sx={{
            mt: 1.5,
            transition: 'padding .25s ease-in-out',
            px: (theme) => `${theme.spacing(navMinimized ? 2 : 3)} !important`
          }}
        >
          <ListItemButton
            className={clsx({
              'Mui-selected':
                anchorEl !== null || groupActive.includes(item.title) || currentActiveGroup.includes(item.title)
            })}
            sx={{
              py: 2.25,
              width: '100%',
              borderRadius: '8px',
              transition: 'padding-left .25s ease-in-out',
              pr: navMinimized ? navLinkPadding : 3,
              pl: navMinimized ? navLinkPadding : 4,
              '&.Mui-selected': {
                backgroundColor: 'action.selected',
                '&:hover': {
                  backgroundColor: 'action.selected'
                }
              }
            }}
          >
            <ListItemIcon
              sx={{
                transition: 'margin .25s ease-in-out',
                ...(navMinimized ? { mr: 0 } : { mr: 2 })
              }}
            >
              {item.icon}
            </ListItemIcon>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                opacity: navMinimized ? 0 : 1,
                overflow: 'hidden'
              }}
            >
              <Translations text={item.title} />
            </Box>
            {item.badgeContent ? (
              <Chip
                size="small"
                label={item.badgeContent}
                color={item.badgeColor || 'primary'}
                sx={{ mr: 1.5, '& .MuiChip-label': { px: 2.5, lineHeight: 1.385, textTransform: 'capitalize' } }}
              />
            ) : null}
          </ListItemButton>
        </ListItem>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          slotProps={{
            paper: {
              sx: {
                borderRadius: '8px !important',
                p: 4,
                '& .MuiMenuItem-root': {
                  px: 2,
                  py: 1
                },
                '& .MuiList-root': {
                  p: 0
                }
              }
            }
          }}
        >
          <Stack direction="row" spacing={4}>
            {item.children?.map((child, idx) => (
              <CanViewNavGroup navGroup={child} key={child.title}>
                {idx > 0 && <Divider orientation="vertical" flexItem />}
                <Box sx={{ minWidth: 180 }}>
                  <Box sx={{ height: '42px', display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h7">{child.title}</Typography>
                  </Box>
                  {child.children?.map((grandChild) => {
                    // Popout group does not support grandchildren
                    if ((grandChild as NavGroup).children) return null
                    const { badgeContent, badgeColor, disabled, path, openInNewTab, title } = grandChild as NavLink
                    return (
                      <CanViewNavLink navLink={grandChild} key={title}>
                        <MenuNavLink
                          component={Link}
                          onClick={(e) => {
                            if (path === undefined) {
                              e.preventDefault()
                              e.stopPropagation()
                            }
                            handleClose()
                            if (navHover) setNavHover(false)
                            if (navVisible) toggleNavVisibility()
                          }}
                          href={path === undefined ? '/' : `${path}`}
                          sx={{ height: '42px', opacity: '1 !important', flex: 1 }}
                          disabled={disabled || false}
                          className={isNavLinkActive(grandChild) ? 'active' : ''}
                          {...(openInNewTab ? { target: '_blank' } : null)}
                        >
                          <Typography variant="body2" color={disabled ? 'text.secondary' : 'text.primary'}>
                            <Translations text={title} />
                          </Typography>

                          {badgeContent ? (
                            <Chip
                              size="small"
                              label={badgeContent}
                              color={badgeColor || 'primary'}
                              sx={{
                                mr: 1.5,
                                ml: 4,
                                '& .MuiChip-label': { px: 2.5, lineHeight: 1.385, textTransform: 'capitalize' }
                              }}
                            />
                          ) : null}
                        </MenuNavLink>
                      </CanViewNavLink>
                    )
                  })}
                </Box>
              </CanViewNavGroup>
            ))}
          </Stack>
        </Menu>
      </>
    </CanViewNavGroup>
  )
}

export default VerticalNavPopoutItem
