import { combineReducers } from '@reduxjs/toolkit'

import chargerOverviewReducer from '~/app/chargers/slice'
import dashboardReducer from '~/app/dashboard/slice'
import firmwareErrorReducer from '~/app/settings/chargers/slice'
import siteOverviewReducer from '~/app/sites/slice'
import vehicleOverviewReducer from '~/app/vehicles/slice'
import { enhancedApi } from '~/data/api/client'
import { authApi } from '~/data/api/client/auth'

import currentOrganizationReducer from './slices/currentOrg'
import displayReducer from './slices/display'
import notificationOverlayReducer from './slices/notifications'

const rootReducer = combineReducers({
  currentOrganization: currentOrganizationReducer,
  dashboard: dashboardReducer,
  display: displayReducer,
  notificationOverlay: notificationOverlayReducer,
  chargerOverview: chargerOverviewReducer,
  firmwareError: firmwareErrorReducer,
  siteOverview: siteOverviewReducer,
  vehicleOverview: vehicleOverviewReducer,
  [enhancedApi.reducerPath]: enhancedApi.reducer,
  [authApi.reducerPath]: authApi.reducer
})

export default rootReducer
