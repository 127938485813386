import { isNull, mergeWith } from 'lodash'

export type FormatterInput = number | string | null | undefined
export type FormatterOptions = Partial<{
  longForm: boolean
  sigFigs: number
  units: string
  omitUnits: boolean
  invalidStr: string
}>

export type FormatterFunction<OwnOptions = {}> = (
  value?: FormatterInput,
  options?: FormatterOptions & OwnOptions
) => string

export type FormatterComponentProps<Key extends string, OwnOptions = {}> = FormatterOptions &
  OwnOptions &
  Partial<Record<Key | 'value', FormatterInput>>

/**
 * Merges the provided formatter options into the default options. If a formatter option is null
 * or undefined, the default option will be used.
 */
export function mergeOptions<T>(defaults: T, options?: Partial<T>): T {
  return mergeWith({}, defaults, options, (objValue, srcValue) => {
    return isNull(srcValue) ? objValue : srcValue
  })
}
