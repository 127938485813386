import { Close } from '@mui/icons-material'
import type { DialogProps, SxProps } from '@mui/material'
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { noop } from 'lodash'
import type { FC, PropsWithChildren } from 'react'
import { createContext, useContext, useMemo, useState } from 'react'

import { LoadingMessage } from './Loaders'

export type OverlayProps<OwnProps = {}> = PropsWithChildren<OwnProps & OverlayControls & { onClose?: () => void }>
type OverlayContextValues = {
  closeOverlay: () => void
  error?: string
  setError: (error?: string) => void
  isDefaultContext: boolean
}

type OverlayControls = {
  closeOverlay: () => void
  isOpen: boolean
  openOverlay: () => void
  setIsOpen: (isOpen: boolean) => void
}

export const OverlayContext = createContext<OverlayContextValues>({
  closeOverlay: noop,
  isDefaultContext: true,
  setError: noop
})

export const useOverlayContext = () => useContext(OverlayContext)

/**
 * Function returning a HOC that conditionally renders the wrapped component if and only if
 * the `isOpen` prop is `true`. This allows us to create overlay components which make potentially-
 * expensive API calls without having to worry about the API call being made when the overlay is
 * not open.
 */
export function createOverlay<OwnProps>(Component: FC<OwnProps>) {
  return function OverlayWrapper(props: OverlayProps<OwnProps>) {
    const { isOpen, closeOverlay, onClose } = props
    const [error, setError] = useState<string>()

    const contextValue = useMemo(
      () => ({
        closeOverlay: () => {
          setError(undefined)
          closeOverlay()
          onClose?.()
        },
        error,
        setError,
        isDefaultContext: false
      }),
      [closeOverlay, onClose, error]
    )

    // Render nothing if the overlay is not open-- this prevents API calls when the overlay is closed
    if (!isOpen) return null
    return (
      <OverlayContext.Provider value={contextValue}>
        <Component {...props} />
      </OverlayContext.Provider>
    )
  }
}

export function useOverlay(): OverlayControls {
  const [isOpen, setIsOpen] = useState(false)
  return useMemo(
    () => ({
      closeOverlay: () => setIsOpen(false),
      isOpen,
      openOverlay: () => setIsOpen(true),
      setIsOpen
    }),
    [isOpen]
  )
}

type OverlayDialogProps = PropsWithChildren<Pick<DialogProps, 'fullWidth' | 'maxWidth'>>

const OverlayDialog = ({ children, ...rest }: OverlayDialogProps) => {
  const { closeOverlay, isDefaultContext } = useOverlayContext()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  // Log a warning if the overlay is being rendered outside of an `OverlayContext.Provider`
  if (isDefaultContext) {
    console.error(
      'An overlay is being rendered outside of an `OverlayContext.Provider`. Did you wrap your ' +
        'overlay component in `createOverlay`?'
    )
  }

  return (
    <Dialog fullScreen={fullScreen} onClose={closeOverlay} open {...rest}>
      {children}
    </Dialog>
  )
}

// Interior overlay padding should be 24px. The value `6` equates to 24px. Padding for the
// `DialogContent` and `DialogActions` components is handled via theme overrides (see `components.ts`).
const INTERIOR_PADDING = 6

// The designs call for 24px margin between the close button and the right edge of the overlay, not
// accounting for the "ripple area". The ripple adds 8px of padding on each side of the button, so
// we need to adjust for this. The value `2` equates to 8px.
const RIPPLE_ADJUSTMENT = 2

type OverlayHeaderProps = PropsWithChildren<{ title: string; direction?: 'row' | 'column'; sx?: SxProps }>

function OverlayHeader({ title, children, direction = 'row', sx = {} }: OverlayHeaderProps) {
  const { closeOverlay } = useOverlayContext()
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ p: INTERIOR_PADDING, pb: 4, pr: INTERIOR_PADDING - RIPPLE_ADJUSTMENT, ...sx }}
    >
      {/* Stack containing the title and any additional header elements */}
      <Stack
        direction={direction}
        alignItems={direction === 'row' ? 'center' : 'flex-start'}
        spacing={2}
        // Prevent the title from wrapping, and keep a 40px margin between the title and the close button
        sx={{ flexWrap: 'nowrap', overflow: 'hidden', mr: 10 - RIPPLE_ADJUSTMENT }}
      >
        <Typography noWrap variant="h6">
          {title}
        </Typography>
        {children}
      </Stack>

      <IconButton data-cy="close-overlay" onClick={closeOverlay}>
        <Close />
      </IconButton>
    </Stack>
  )
}

type OverlayContentProps = PropsWithChildren<{ loading?: boolean; sx?: SxProps }>

function OverlayContent({ children, loading = false, sx }: OverlayContentProps) {
  return (
    <>
      <Divider />
      <DialogContent sx={sx}>{loading ? <LoadingMessage height="200px" /> : children}</DialogContent>
    </>
  )
}

function OverlayActions({ ...rest }: PropsWithChildren) {
  const { error, setError } = useOverlayContext()
  return (
    <>
      {error && (
        <Alert severity="error" sx={{ mx: 4, mt: 2 }} onClose={() => setError(undefined)}>
          {error}
        </Alert>
      )}
      <DialogActions {...rest} />
    </>
  )
}

export const Overlay = Object.assign(OverlayDialog, {
  Actions: OverlayActions,
  Content: OverlayContent,
  Header: OverlayHeader
})
