import { Direction } from '@mui/material'
import { pick } from 'lodash'
import { PropsWithChildren, createContext, useContext, useState } from 'react'

import { Mode } from '@core/layouts/types'

import themeConfig from '~/configs/themeConfig'

export type Settings = {
  mode: Mode
  direction: Direction
  navCollapsed: boolean
  themeColor: 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success'
}

export type SettingsContextValue = {
  settings: Settings
  saveSettings: (updatedSettings: Settings) => void
}

const initialSettings: Settings = {
  themeColor: 'primary',
  mode: themeConfig.mode,
  direction: themeConfig.direction,
  navCollapsed: themeConfig.navCollapsed
}

const storedFields: Array<keyof Settings> = ['navCollapsed']

/**
 * Loads stored settings from localStorage and merges them with the initial settings.
 */
const restoreSettings = (): Settings => {
  const storedSettings = (() => {
    try {
      const stored = window.localStorage.getItem('settings')
      if (stored) {
        const parsed = JSON.parse(stored)
        return pick(parsed, storedFields)
      } else {
        return null
      }
    } catch (err) {
      return null
    }
  })()

  return { ...initialSettings, ...storedSettings }
}

// set settings in localStorage
const storeSettings = (settings: Settings) => {
  const toStore = pick(settings, storedFields)
  window.localStorage.setItem('settings', JSON.stringify(toStore))
}

// ** Create Context
export const SettingsContext = createContext<SettingsContextValue>({
  saveSettings: () => null,
  settings: initialSettings
})

export const useSettings = (): SettingsContextValue => useContext(SettingsContext)

export const SettingsProvider = ({ children }: PropsWithChildren) => {
  const [settings, setSettings] = useState(() => restoreSettings())

  const saveSettings = (updatedSettings: Settings) => {
    storeSettings(updatedSettings)
    setSettings(updatedSettings)
  }

  return <SettingsContext.Provider value={{ settings, saveSettings }}>{children}</SettingsContext.Provider>
}

export const SettingsConsumer = SettingsContext.Consumer
