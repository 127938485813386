import MuiAppBar, { AppBarProps } from '@mui/material/AppBar'
import MuiToolbar, { ToolbarProps } from '@mui/material/Toolbar'
import { alpha, styled, useTheme } from '@mui/material/styles'
import useScrollTrigger from '@mui/material/useScrollTrigger'

import AppBarContent from '~/layouts/components/AppBarContent'
import { sideDrawerWidth } from '~/layouts/components/ContentShiftingDrawer'
import { useBannerOffset, useSideDrawerControls } from '~/store/slices/display'

interface Props {
  hidden: boolean
  toggleNavVisibility: () => void
}

const AppBar = styled(MuiAppBar)<AppBarProps>(({ theme }) => ({
  transition: 'width .25s ease-in-out',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 6),
  backgroundColor: 'transparent',
  color: theme.palette.text.primary,
  minHeight: theme.mixins.toolbar.minHeight,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  }
}))

const Toolbar = styled(MuiToolbar)<ToolbarProps>(({ theme }) => ({
  width: '100%',
  padding: '0 !important',
  minHeight: `${theme.mixins.toolbar.minHeight}px !important`,
  transition: 'padding .25s ease-in-out, box-shadow .25s ease-in-out, background-color .25s ease-in-out'
}))

const LayoutAppBar = (props: Props) => {
  const bannerOffset = useBannerOffset()
  const { isOpen } = useSideDrawerControls()

  const theme = useTheme()
  const scrollTrigger = useScrollTrigger({ threshold: 0, disableHysteresis: true })

  const appBarFixedStyles = () => {
    return {
      px: `${theme.spacing(6)} !important`,
      boxShadow: 3,
      backdropFilter: 'blur(8px)',
      backgroundColor: alpha(theme.palette.background.paper, 0.9)
    }
  }

  return (
    <AppBar
      elevation={0}
      color="default"
      className="layout-navbar"
      sx={{
        top: bannerOffset,
        ...(isOpen && {
          width: `calc(100% - ${sideDrawerWidth}px)`,
          marginLeft: 0,
          marginRight: `${sideDrawerWidth}px`
        })
      }}
      position="sticky"
    >
      <Toolbar
        className="navbar-content-container"
        sx={{
          ...(scrollTrigger && { ...appBarFixedStyles() })
        }}
      >
        <AppBarContent {...props} />
      </Toolbar>
    </AppBar>
  )
}

export default LayoutAppBar
