import dayjs from 'dayjs'

import type { VehicleModel } from '../api/client'
import { type VehicleStatus, vehicleStatuses } from '../types/vehicle'
import { mergeOptions } from './types'

type PartialVehicle = Pick<VehicleModel, 'vehicleNm' | 'make' | 'model' | 'modelYear' | 'id'>
type VehicleOptions = {
  invalidStr?: string
  useOriginalName?: boolean
}

const defaultOptions = {
  invalidStr: 'Unknown Vehicle',
  useOriginalName: false
}

export function formatVehicleName(vehicle?: PartialVehicle, options?: VehicleOptions) {
  const { invalidStr, useOriginalName } = mergeOptions(defaultOptions, options)
  return vehicle ? parseVehicleName(vehicle, useOriginalName) : invalidStr
}

/**
 * Formats a vehicle's name for export (to a CSV). Uses an empty string for invalid values.
 */
export const formatVehicleNameExport = (vehicle?: PartialVehicle) => {
  return formatVehicleName(vehicle, { invalidStr: '' })
}

export function isVehicleStatus(unit?: string): unit is VehicleStatus {
  return unit !== undefined && (vehicleStatuses as readonly string[]).includes(unit)
}

/**
 * Returns true if the vehicle is considered stale. A vehicle is considered stale if:
 *   1. there's no `telematicsTimestamp`; or
 *   2. the telematics data is older than 72 hours
 */
export function vehicleIsStale(telematicsTimestamp?: string) {
  if (!telematicsTimestamp) return false
  return dayjs().subtract(72, 'hours').isAfter(telematicsTimestamp)
}

type VehicleNameEntity = Pick<VehicleModel, 'vehicleNm' | 'vehicleDisplayNm'>
export function parseVehicleName(vehicle?: VehicleNameEntity, useOriginalName?: boolean) {
  const { vehicleNm, vehicleDisplayNm } = vehicle ?? {}
  if (!vehicleNm) return 'Unknown Vehicle'
  return useOriginalName ? vehicleNm : vehicleDisplayNm ?? vehicleNm
}
