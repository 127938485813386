import { Box, useTheme } from '@mui/material'

import { useSettings } from '@core/context/settingsContext'

import Link from '~/components/Link'
import { useCurrentOrg } from '~/data/api/hooks/organizations'
import routeDirectory from '~/navigation/routes'

const defaultLogoUrl = '/images/logos/synop-logo-600-alpha.png'
const defaultMiniLogoUrl = '/images/logos/synop-logo-mini.png'

type HeaderBrandingProps = {
  navHover: boolean
}

export default function HeaderBranding({ navHover }: HeaderBrandingProps) {
  const { navCollapsed } = useSettings().settings
  const { org } = useCurrentOrg()
  const hasOrgLogo = Boolean(org?.logoUrl)
  const logoUrl = org?.logoUrl || defaultLogoUrl
  const boltOnly = navCollapsed && !navHover
  const theme = useTheme()

  return (
    // marginRight ensures spacing between the org logo and the drawer expansion controls
    <Link href={routeDirectory.index} style={{ marginRight: 10 }}>
      {/* minWidth prevents the drawer expansion controls from rendering when collapsed */}
      <Box sx={{ position: 'relative', height: 40, minWidth: 100 }}>
        <Box
          alt="Synop Logo"
          component="img"
          src={logoUrl}
          sx={{
            // `objectFit: 'contain'` downscales the logo to fit in the container. Aspect ratio is
            // preserved. If the logo is smaller than the container, it does not change size.
            objectFit: 'contain',
            height: '100%',
            width: '100%',

            // Hide the larger logo when the drawer is closed (and not hovered)
            opacity: boltOnly ? 0 : 1,
            transition: theme.transitions.create(['opacity'])
          }}
        />

        <Box
          alt="Synop Logo"
          component="img"
          src={org?.iconUrl ?? defaultMiniLogoUrl}
          sx={{
            // Aligns the bolt with the "y" in "Synop" when the drawer is opened; centers it when
            // the drawer is closed
            position: 'absolute',
            top: boltOnly ? 3 : 5.5,
            left: boltOnly ? 12 : 17.5,

            // When the drawer is open and the larger logo is rendering, we only want to show the
            // bolt logo if there is no organization logo
            opacity: hasOrgLogo && !boltOnly ? 0 : 1,

            // Makes the bolt grow when the drawer is closed
            height: boltOnly ? boltHeightLarge : boltHeightSmall,
            width: boltOnly ? boltHeightLarge : boltHeightSmall,
            transition: theme.transitions.create(['height', 'width', 'top', 'left', 'opacity'])
          }}
        />
      </Box>
    </Link>
  )
}

const boltHeightSmall = 36
const boltHeightLarge = 44
