import { Box, Button, Grid, Stack, Tab, Tabs, Typography } from '@mui/material'
import { usePathname, useRouter } from 'next/navigation'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

import Link from '~/components/Link'
import { Overlay, createOverlay, useOverlayContext } from '~/components/Overlay'
import type { NotificationModel } from '~/data/api/client'
import { useAcknowledgeSentNotificationMutation } from '~/data/api/client'
import { useCharger } from '~/data/api/hooks/chargers'
import { useGetOrg } from '~/data/api/hooks/organizations'
import { useGetSite } from '~/data/api/hooks/sites'
import { useVehicle } from '~/data/api/hooks/vehicles'
import { formatVehicleName } from '~/data/formatters/vehicle'
import useTimeFormatter from '~/hooks/useTimeFormatter'
import routeDirectory from '~/navigation/routes'
import { removeOverlayNotifications, setOverlayOpen } from '~/store/slices/notifications'
import { usePaletteColor } from '~/theme/hooks'

import PriorityPill from './PriorityPill'
import StatusTile from './StatusTile'

type NotificationOverlayProps = {
  notifications: NotificationModel[]
}

const NotificationsOverlay = createOverlay(({ notifications }: NotificationOverlayProps) => {
  const router = useRouter()
  const pathname = usePathname()
  const { notifications: notificationsRoute } = routeDirectory
  const dispatch = useDispatch()
  const formatDateTime = useTimeFormatter()

  const [acknowledgeNotification] = useAcknowledgeSentNotificationMutation()
  const isMultiple = notifications.length > 1

  const [currentNotificationIndex, setCurrentNotificationIndex] = useState(0)
  const currentNotification = notifications[currentNotificationIndex]

  const onAcknowledge = () => {
    if (!currentNotification) return

    dispatch(removeOverlayNotifications([currentNotification.id]))
    acknowledgeNotification({
      acknowledgeNotificationsModel: {
        sentNotificationIds: [currentNotification.id]
      }
    })

    if (currentNotificationIndex >= notifications.length - 1) {
      setCurrentNotificationIndex(currentNotificationIndex - 1)
    }
  }
  const { closeOverlay } = useOverlayContext()

  const ackDateTime = currentNotification?.acknowledgedDateTime

  return (
    <Overlay fullWidth maxWidth="md">
      <Overlay.Header
        title={isMultiple ? `Critical Notifications (${notifications.length})` : notifications[0]?.alertName || ''}
      >
        {!isMultiple && <PriorityPill priority={notifications[0]?.priority} />}
      </Overlay.Header>
      <Overlay.Content>
        <Grid container spacing={2}>
          {isMultiple && (
            <Grid item xs={12}>
              <Tabs
                onChange={(_, index) => setCurrentNotificationIndex(index)}
                scrollButtons="auto"
                value={currentNotificationIndex}
                variant="scrollable"
              >
                {notifications.map((notification) => (
                  <Tab
                    key={notification.id}
                    label={
                      notification.alertName.length > 30
                        ? `${notification.alertName.slice(0, 27)}...`
                        : notification.alertName
                    }
                  />
                ))}
              </Tabs>
            </Grid>
          )}
          <NotificationOverlayBody notification={currentNotification} />
        </Grid>
      </Overlay.Content>
      <Overlay.Actions>
        {pathname !== notificationsRoute && (
          <Button
            onClick={() => {
              router.push(notificationsRoute)
              dispatch(setOverlayOpen(false))
              closeOverlay()
            }}
            variant="outlined"
          >
            View All
          </Button>
        )}
        {currentNotification?.acknowledged ? (
          <Typography variant="caption">Acknowledged at {formatDateTime(ackDateTime).timeOnDate}</Typography>
        ) : (
          <Button onClick={onAcknowledge} variant="contained">
            Acknowledge
          </Button>
        )}
      </Overlay.Actions>
    </Overlay>
  )
})

type NotificationOverlayBodyProps = {
  notification?: NotificationModel
}

const NotificationOverlayBody = ({ notification }: NotificationOverlayBodyProps) => {
  const { org } = useGetOrg(notification?.organizationId)
  const backgroundColor = usePaletteColor('primary', 0.1)

  if (!notification) return null

  const assetComponentMap = {
    Charger: ChargerAssetTile,
    Vehicle: VehicleAssetTile,
    Site: SiteAssetTile
  }

  const AssetTile = assetComponentMap[notification.assetType]

  return (
    <Grid container item spacing={2} xs={12}>
      <Grid item xs={12}>
        <Box sx={{ backgroundColor, p: 2 }}>
          <Typography variant="subtitle1">{notification.message ?? ''}</Typography>
        </Box>
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <Stack>
          <Typography variant="caption">Status</Typography>
          <StatusTile notification={notification} emphasized />
        </Stack>
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <Stack>
          <Typography variant="caption">Organization</Typography>
          <Typography variant="subtitle1">{org?.organizationNm ?? ''}</Typography>
        </Stack>
      </Grid>
      <AssetTile assetId={notification.assetId ?? ''} />
    </Grid>
  )
}

type AssetTileProps = {
  assetId: string
}

const SiteAssetTile = ({ assetId }: AssetTileProps) => {
  const { sites } = routeDirectory
  const { site } = useGetSite(assetId)
  const siteName = site?.depotNm
  return (
    <Grid item md={3} sm={6} xs={12}>
      <Stack>
        <Typography variant="caption">Site</Typography>
        <OverlayLink href={sites.details(assetId)}>{siteName}</OverlayLink>
      </Stack>
    </Grid>
  )
}

const ChargerAssetTile = ({ assetId }: AssetTileProps) => {
  const { chargers, sites } = routeDirectory
  const { charger } = useCharger(assetId)
  const siteName = charger?.depotName
  return (
    <>
      {siteName && (
        <Grid item md={3} sm={6} xs={12}>
          <Stack>
            <Typography variant="caption">Site</Typography>
            <OverlayLink href={sites.details(charger?.depotId ?? '')}>{siteName}</OverlayLink>
          </Stack>
        </Grid>
      )}
      <Grid item md={siteName ? 3 : 6} sm={siteName ? 6 : 12} xs={12}>
        <Stack>
          <Typography variant="caption">Charger</Typography>
          <OverlayLink href={chargers.details(charger?.chargerId ?? '')}>{charger?.chargerName}</OverlayLink>
        </Stack>
      </Grid>
    </>
  )
}

const VehicleAssetTile = ({ assetId }: AssetTileProps) => {
  const { vehicles, sites } = routeDirectory
  const { vehicle } = useVehicle(assetId)
  const { site } = useGetSite(vehicle?.homeSiteId)
  const siteName = site?.depotNm
  return (
    <>
      {siteName && (
        <Grid item md={3} sm={6} xs={12}>
          <Stack>
            <Typography variant="caption">Home Site</Typography>
            <OverlayLink href={sites.details(vehicle?.homeSiteId ?? '')}>{siteName}</OverlayLink>
          </Stack>
        </Grid>
      )}
      <Grid item md={siteName ? 3 : 6} sm={siteName ? 6 : 12} xs={12}>
        <Stack>
          <Typography variant="caption">Vehicle</Typography>
          <OverlayLink href={vehicles.details(vehicle?.id ?? '')}>{formatVehicleName(vehicle)}</OverlayLink>
        </Stack>
      </Grid>
    </>
  )
}

const OverlayLink = ({ href, children }: { href: string; children: React.ReactNode }) => {
  const dispatch = useDispatch()
  const { closeOverlay } = useOverlayContext()
  return (
    <Typography noWrap variant="subtitle1">
      <Link
        href={href}
        onClick={() => {
          dispatch(setOverlayOpen(false))
          closeOverlay()
        }}
      >
        {children}
      </Link>
    </Typography>
  )
}

export default NotificationsOverlay
