import type { VehicleModel as ApiVehicleModel, VehicleMetricsModel } from '../api/client'
import type {
  DistanceSourceAttribute,
  EfficiencySourceAttribute,
  EmissionsSourceAttribute,
  EnergySourceAttribute,
  FuelSourceAttribute
} from './units'

export const vehicleStatuses = ['Off', 'Charging', 'In Motion', 'Idle', 'Plugged In'] as const
export type VehicleStatus = (typeof vehicleStatuses)[number]

export type VehicleStatusSourceAttribute = {
  value: VehicleStatus
} & Omit<ApiVehicleModel['vehicleStatus'], 'value'>

export type VehicleTableMetrics = {
  id: string
  totalTrips: number
  numDrivingDays: number
  fuelSaved: FuelSourceAttribute
  energyUsed: EnergySourceAttribute
  efficiency: EfficiencySourceAttribute
  distanceDriven: DistanceSourceAttribute
  carbonEmissionsSaved: EmissionsSourceAttribute
}

export type VehicleDashboardMetrics = {
  id: string
  carbonEmissionsSaved: EmissionsSourceAttribute
  distanceDriven: DistanceSourceAttribute
  efficiency: EfficiencySourceAttribute
  energyAdded: EnergySourceAttribute
  energyUsed: EnergySourceAttribute
  fuelSaved?: FuelSourceAttribute
} & Omit<
  VehicleMetricsModel,
  'carbonEmissionsSaved' | 'distanceDriven' | 'efficiency' | 'energyAdded' | 'energyUsed' | 'id'
>
