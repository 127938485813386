import {
  AdminPanelSettingsOutlined,
  AssessmentOutlined,
  AttachMoneyOutlined,
  BoltOutlined,
  HomeOutlined,
  LocalShippingOutlined,
  LocationOnOutlined,
  TuneOutlined
} from '@mui/icons-material'
import { Box } from '@mui/material'
import { compact } from 'lodash'
import { useMemo } from 'react'
import type { NavItemsType, NavLink } from 'src/@core/layouts/types'

import { useCurrentOrg } from '~/data/api/hooks/organizations'
import { useMediaQuery } from '~/theme/hooks'

import routeDirectory from '../routes'

const { chargers, dashboard, settings, sites, vehicles } = routeDirectory

const adminOnly = {
  action: 'manage',
  subject: 'admin'
}

const iconBox = <Box sx={{ width: 14 }} />
const homeChargingLink = {
  title: 'Home Charging',
  icon: iconBox,
  path: settings.homeCharging.root,
  ...adminOnly
}

const workplaceChargingLink = {
  title: 'Workplace Charging',
  icon: iconBox,
  path: settings.workplaceCharging,
  ...adminOnly
}

const fleetBillingLink = {
  title: 'Fleet Billing',
  icon: iconBox,
  path: settings.fleetBilling,
  ...adminOnly
}

const publicChargingLink = {
  title: 'Public Charging',
  icon: iconBox,
  path: settings.publicCharging,
  ...adminOnly
}

const costExplorerLink = {
  title: 'Cost Explorer',
  icon: iconBox,
  path: settings.costExplorer,
  ...adminOnly
}

const userAccessChildren: NavLink[] = [
  {
    title: 'Users',
    icon: <></>,
    path: settings.users,
    subject: 'read',
    action: 'user_settings'
  },
  {
    title: 'SSO',
    icon: <></>,
    badgeContent: 'Coming Soon',
    badgeColor: 'info',
    disabled: true,
    ...adminOnly
  }
]

const orgSettingsChildren: NavLink[] = [
  {
    title: 'Settings',
    icon: <></>,
    path: settings.orgManagement,
    ...adminOnly
  }
]

const orgManagementChildren: NavLink[] = [
  {
    title: 'Organizations',
    icon: <></>,
    path: settings.orgs,
    ...adminOnly
  }
]

const siteAdminChildren: NavLink[] = [
  {
    title: 'Chargers',
    icon: <></>,
    path: settings.chargers.root
  },
  {
    title: 'Vehicles',
    icon: <></>,
    path: settings.vehicles.root
  },
  {
    title: 'Fleets',
    icon: <></>,
    path: settings.fleets
  },
  {
    title: 'Sites',
    icon: <></>,
    path: settings.sites.root
  },
  {
    title: 'Locations of Interest',
    icon: <></>,
    path: settings.locations
  },
  {
    title: 'Alerts',
    icon: <></>,
    path: settings.alerts,
    ...adminOnly
  }
]

const developerChildren = [
  {
    title: 'Documentation',
    icon: <></>,
    path: 'https://docs.synop.ai',
    openInNewTab: true
  },
  {
    title: 'API Usage',
    icon: <></>,
    path: routeDirectory.settings.apiKeys
  }
]

export default function useNavItems(): NavItemsType {
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const { org: currentOrganization } = useCurrentOrg()

  const { hasCostExplorer, reimbursementsStatus, fleetBillingStatus, workplaceChargingStatus, publicChargingStatus } =
    currentOrganization ?? {}

  const billingItems = useMemo<NavItemsType>(() => {
    const showHomeCharging = ['ENABLED', 'DISABLED'].includes(reimbursementsStatus ?? '')
    const showWorkplaceCharging = ['ENABLED', 'DISABLED'].includes(workplaceChargingStatus ?? '')
    const showPublicCharging = ['ENABLED', 'DISABLED'].includes(publicChargingStatus ?? '')
    const showFleetBilling = ['ENABLED', 'DISABLED'].includes(fleetBillingStatus ?? '')
    // If neither of the billing features are turned on, don't show the billing section
    if (!showHomeCharging && !showWorkplaceCharging && !showFleetBilling && !hasCostExplorer && !showPublicCharging)
      return []

    return [
      {
        title: 'Billing',
        icon: <AttachMoneyOutlined />,
        ...adminOnly,
        children: compact([
          showHomeCharging && homeChargingLink,
          showWorkplaceCharging && workplaceChargingLink,
          showFleetBilling && fleetBillingLink,
          hasCostExplorer && costExplorerLink,
          showPublicCharging && publicChargingLink
        ])
      }
    ]
  }, [fleetBillingStatus, hasCostExplorer, publicChargingStatus, reimbursementsStatus, workplaceChargingStatus])

  return [
    {
      title: 'Dashboard',
      icon: <HomeOutlined />,
      path: dashboard.root
    },
    {
      title: 'Vehicles',
      icon: <LocalShippingOutlined />,
      path: vehicles.root
    },
    {
      title: 'Sites',
      icon: <LocationOnOutlined />,
      path: sites.root
    },
    {
      title: 'Chargers',
      icon: <BoltOutlined />,
      path: chargers.root
    },
    {
      title: 'Reports',
      icon: <AssessmentOutlined />,
      path: routeDirectory.reports
    },
    ...billingItems,
    {
      sectionTitle: 'Platform'
    },
    {
      title: 'Admin',
      popout: isMdUp,
      icon: <AdminPanelSettingsOutlined />,
      subject: 'admin_settings',
      action: 'read',
      children: isMdUp
        ? [
            {
              title: 'User & Access Management',
              icon: <></>,
              children: userAccessChildren,
              subject: 'read',
              action: 'user_settings'
            },
            {
              title: 'Organization Settings',
              icon: <></>,
              children: orgSettingsChildren,
              ...adminOnly
            },
            {
              title: 'Organization Management',
              icon: <></>,
              children: orgManagementChildren,
              ...adminOnly
            }
          ]
        : [...userAccessChildren, ...orgSettingsChildren, ...orgManagementChildren]
    },
    {
      title: 'Manage',
      popout: isMdUp,
      icon: <TuneOutlined />,
      children: isMdUp
        ? [
            {
              title: 'Site Admin',
              icon: <></>,
              children: siteAdminChildren
            },
            {
              title: 'Developer',
              icon: <></>,
              children: developerChildren
            }
          ]
        : [...siteAdminChildren, ...developerChildren]
    }
  ]
}
