import { NavGroup, NavItemsType, NavLink, NavSectionTitle } from 'src/@core/layouts/types'

import VerticalNavGroup from './VerticalNavGroup'
import VerticalNavLink from './VerticalNavLink'
import VerticalNavPopoutItem from './VerticalNavPopoutItem'
import VerticalNavSectionTitle from './VerticalNavSectionTitle'

interface Props {
  parent?: NavGroup
  navHover?: boolean
  navVisible?: boolean
  groupActive: string[]
  isSubToSub?: NavGroup
  currentActiveGroup: string[]
  navigationBorderWidth: number
  setGroupActive: (value: string[]) => void
  setCurrentActiveGroup: (item: string[]) => void
  setNavHover?: (values: boolean) => void
  navItems?: NavItemsType
}

const resolveNavItemComponent = (item: NavGroup | NavLink | NavSectionTitle) => {
  if ((item as NavSectionTitle).sectionTitle) return VerticalNavSectionTitle
  if ((item as NavGroup).popout) return VerticalNavPopoutItem
  if ((item as NavGroup).children) return VerticalNavGroup

  return VerticalNavLink
}

const VerticalNavItems = (props: Props) => {
  const { navItems } = props

  const RenderMenuItems = navItems?.map((item, index: number) => {
    const TagName: any = resolveNavItemComponent(item)

    return <TagName {...props} key={index} item={item} />
  })

  return <>{RenderMenuItems}</>
}

export default VerticalNavItems
